.swatch-circle-beige {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #f5f5dc;
  border-radius: calc($diameter / 2);
  display: block;
  position: relative; }
  .swatch-circle-beige.disabled {
    opacity: 0.2; }

.swatch-filter-beige {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #f5f5dc;
  border-radius: calc($diameter / 2);
  background-color: #f5f5dc;
  display: block;
  position: relative; }
  .swatch-filter-beige.disabled {
    opacity: 0.2; }

.swatch-circle-black {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #000;
  border-radius: calc($diameter / 2);
  display: block;
  position: relative; }
  .swatch-circle-black.disabled {
    opacity: 0.2; }

.swatch-filter-black {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #000;
  border-radius: calc($diameter / 2);
  background-color: #000;
  display: block;
  position: relative; }
  .swatch-filter-black.disabled {
    opacity: 0.2; }

.swatch-circle-blue {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #0070d2;
  border-radius: calc($diameter / 2);
  display: block;
  position: relative; }
  .swatch-circle-blue.disabled {
    opacity: 0.2; }

.swatch-filter-blue {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #0070d2;
  border-radius: calc($diameter / 2);
  background-color: #0070d2;
  display: block;
  position: relative; }
  .swatch-filter-blue.disabled {
    opacity: 0.2; }

.swatch-circle-brown {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #a52a2a;
  border-radius: calc($diameter / 2);
  display: block;
  position: relative; }
  .swatch-circle-brown.disabled {
    opacity: 0.2; }

.swatch-filter-brown {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #a52a2a;
  border-radius: calc($diameter / 2);
  background-color: #a52a2a;
  display: block;
  position: relative; }
  .swatch-filter-brown.disabled {
    opacity: 0.2; }

.swatch-circle-green {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #008827;
  border-radius: calc($diameter / 2);
  display: block;
  position: relative; }
  .swatch-circle-green.disabled {
    opacity: 0.2; }

.swatch-filter-green {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #008827;
  border-radius: calc($diameter / 2);
  background-color: #008827;
  display: block;
  position: relative; }
  .swatch-filter-green.disabled {
    opacity: 0.2; }

.swatch-circle-grey {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #8f979d;
  border-radius: calc($diameter / 2);
  display: block;
  position: relative; }
  .swatch-circle-grey.disabled {
    opacity: 0.2; }

.swatch-filter-grey {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #8f979d;
  border-radius: calc($diameter / 2);
  background-color: #8f979d;
  display: block;
  position: relative; }
  .swatch-filter-grey.disabled {
    opacity: 0.2; }

.swatch-circle-navy {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #000080;
  border-radius: calc($diameter / 2);
  display: block;
  position: relative; }
  .swatch-circle-navy.disabled {
    opacity: 0.2; }

.swatch-filter-navy {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #000080;
  border-radius: calc($diameter / 2);
  background-color: #000080;
  display: block;
  position: relative; }
  .swatch-filter-navy.disabled {
    opacity: 0.2; }

.swatch-circle-orange {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #ffa500;
  border-radius: calc($diameter / 2);
  display: block;
  position: relative; }
  .swatch-circle-orange.disabled {
    opacity: 0.2; }

.swatch-filter-orange {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #ffa500;
  border-radius: calc($diameter / 2);
  background-color: #ffa500;
  display: block;
  position: relative; }
  .swatch-filter-orange.disabled {
    opacity: 0.2; }

.swatch-circle-pink {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #fe249a;
  border-radius: calc($diameter / 2);
  display: block;
  position: relative; }
  .swatch-circle-pink.disabled {
    opacity: 0.2; }

.swatch-filter-pink {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #fe249a;
  border-radius: calc($diameter / 2);
  background-color: #fe249a;
  display: block;
  position: relative; }
  .swatch-filter-pink.disabled {
    opacity: 0.2; }

.swatch-circle-purple {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #800080;
  border-radius: calc($diameter / 2);
  display: block;
  position: relative; }
  .swatch-circle-purple.disabled {
    opacity: 0.2; }

.swatch-filter-purple {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #800080;
  border-radius: calc($diameter / 2);
  background-color: #800080;
  display: block;
  position: relative; }
  .swatch-filter-purple.disabled {
    opacity: 0.2; }

.swatch-circle-red {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #f00;
  border-radius: calc($diameter / 2);
  display: block;
  position: relative; }
  .swatch-circle-red.disabled {
    opacity: 0.2; }

.swatch-filter-red {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #f00;
  border-radius: calc($diameter / 2);
  background-color: #f00;
  display: block;
  position: relative; }
  .swatch-filter-red.disabled {
    opacity: 0.2; }

.swatch-circle-white {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #fff;
  border-radius: calc($diameter / 2);
  display: block;
  position: relative; }
  .swatch-circle-white.disabled {
    opacity: 0.2; }

.swatch-filter-white {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #fff;
  border-radius: calc($diameter / 2);
  background-color: #fff;
  display: block;
  position: relative; }
  .swatch-filter-white.disabled {
    opacity: 0.2; }

.swatch-circle-yellow {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #ff0;
  border-radius: calc($diameter / 2);
  display: block;
  position: relative; }
  .swatch-circle-yellow.disabled {
    opacity: 0.2; }

.swatch-filter-yellow {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #ff0;
  border-radius: calc($diameter / 2);
  background-color: #ff0;
  display: block;
  position: relative; }
  .swatch-filter-yellow.disabled {
    opacity: 0.2; }

.swatch-circle-miscellaneous {
  background: -webkit-gradient(linear, left bottom, left top, color-stop(0, #821e91), color-stop(25%, #821e91), color-stop(25%, #edd134), color-stop(50%, yellow), color-stop(50%, #edd134), color-stop(50%, #59ba00), color-stop(76%, #59ba00), color-stop(76%, #111), to(#111)), -webkit-gradient(linear, left bottom, left top, color-stop(0, #0e5cd1), color-stop(50%, #0e5cd1), color-stop(50%, #e20b0b), to(#e20b0b));
  background: linear-gradient(0deg, #821e91 0, #821e91 25%, #edd134 25%, yellow 50%, #edd134 50%, #59ba00 50%, #59ba00 76%, #111 76%, #111 100%), linear-gradient(0deg, #0e5cd1 0, #0e5cd1 50%, #e20b0b 50%, #e20b0b 100%);
  background-repeat: repeat-y, repeat;
  background-size: 50% 100%, 100% 100%;
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  border-radius: 1.25em;
  display: block;
  height: 2.5em;
  position: relative;
  -webkit-transform: rotate(35deg);
          transform: rotate(35deg);
  width: 2.5em; }
  .swatch-circle-miscellaneous.disabled {
    opacity: 0.2; }
  .swatch-circle-miscellaneous.selected::after {
    -webkit-transform: rotate(-35deg);
            transform: rotate(-35deg); }

.category-tile {
  position: relative; }
  .category-tile h1,
  .category-tile h2 {
    font-size: 1.75rem;
    position: absolute;
    bottom: 1.875rem;
    left: 1.875rem;
    color: #fff; }
  .category-tile::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(60%, transparent), to(rgba(0, 0, 0, 0.5)));
    background-image: linear-gradient(to bottom, transparent 60%, rgba(0, 0, 0, 0.5) 100%); }

.refinement-bar {
  overflow: auto; }
  .refinement-bar ul {
    padding-left: 0; }
  @media (max-width: 768.98px) {
    .refinement-bar {
      background-color: #fff;
      -webkit-box-shadow: 0 0.313em 0.938em rgba(0, 0, 0, 0.5);
              box-shadow: 0 0.313em 0.938em rgba(0, 0, 0, 0.5);
      display: none;
      left: 0;
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 1; } }
  @media (max-width: 543.98px) {
    .refinement-bar {
      position: fixed;
      height: 100%; } }
  @media (min-width: 769px) {
    .refinement-bar {
      display: block !important; } }

.filter-header {
  margin-left: calc($grid-gutter-width / 2 * -1);
  margin-right: calc($grid-gutter-width / 2 * -1); }

.header-bar {
  background-color: #eee;
  border-bottom: 0.063em solid #eee; }
  .header-bar button.close {
    font-size: 1rem;
    font-weight: normal;
    opacity: 1; }
  .header-bar .fa.fa-close::before {
    font-size: 1.25em; }
  @media (min-width: 544px) and (max-width: 768.98px) {
    .header-bar {
      padding: 0.938em 2.813em; } }
  @media (max-width: 543.98px) {
    .header-bar {
      padding: 0.938em; } }

.refinements .header,
.refinements .values {
  padding: 0.313em 0; }
  @media (min-width: 544px) and (max-width: 768.98px) {
    .refinements .header li,
    .refinements .values li {
      display: inline-block; } }

.refinements .header {
  font-size: 1.125em;
  color: #444; }

.refinements ul {
  overflow: auto; }
  .refinements ul li {
    padding-left: 1px; }
    .refinements ul li button {
      border: none;
      color: var(--skin-link-color-1);
      background-color: transparent; }

.refinements li {
  list-style-type: none;
  padding-left: 0; }
  .refinements li.color-attribute {
    display: inline-block;
    padding-left: 0; }
    .refinements li.color-attribute button {
      padding: 0; }
  .refinements li.disabled {
    opacity: 0.5; }

@media (max-width: 543.98px) {
  .refinements {
    padding: 0; } }

.refinements .card-header h2 {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  margin-bottom: 0; }

.secondary-bar {
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 1em;
  overflow: auto; }
  @media (max-width: 768.98px) {
    .secondary-bar button.reset {
      float: right; } }
  @media (min-width: 544px) and (max-width: 768.98px) {
    .secondary-bar {
      padding: 0.938em 2.813em; } }
  @media (max-width: 543.98px) {
    .secondary-bar {
      padding: 0.938em; } }

.refinement-header {
  overflow: auto; }

.disabled {
  pointer-events: none; }

.grid-header,
.content-grid-header {
  margin-top: 1em;
  margin-bottom: 1em; }
  @media (max-width: 543.98px) {
    .grid-header .result-count,
    .content-grid-header .result-count {
      padding-bottom: 0.938em; }
    .grid-header .filter-results,
    .content-grid-header .filter-results {
      display: block;
      width: 100%; } }
  .grid-header select,
  .content-grid-header select {
    width: 100%; }

.result-count {
  font-size: 0.875rem; }

.refinement {
  margin-bottom: 1em; }
  @media (max-width: 768.98px) {
    .refinement.card {
      border-top: 0 none;
      border-left: 0 none;
      border-right: 0 none;
      border-radius: 0;
      margin-bottom: 0; }
    .refinement .card-header {
      border-bottom: 0 none;
      padding: 0.25rem 1rem; }
    .refinement .card-body {
      padding: 0 1rem; } }
  .refinement a {
    text-decoration: none; }
  .refinement.refinement-category li {
    margin-left: 0.938em;
    display: block; }
  .refinement.refinement-category > ul > li {
    margin-left: 0; }

.search-banner {
  background-image: url("../images/search.jpg");
  background-position-y: 40%; }

.search-keywords {
  font-weight: bold; }

.filter-bar ul {
  padding-left: calc($grid-gutter-width / 2); }

.filter-bar li {
  list-style: none;
  float: left;
  margin-left: 0.3125em;
  margin-right: 0.3125em; }
  .filter-bar li:first-child {
    margin-left: 0; }
  .filter-bar li.filter-value {
    border: 1px solid #ccc;
    padding: 0.3125em;
    border-radius: 3px;
    position: relative; }
    .filter-bar li.filter-value button {
      color: black;
      border: none;
      padding: 0 2rem 0 0.5rem; }
      .filter-bar li.filter-value button::after {
        content: "\f00d";
        font: normal normal normal 14px/1 FontAwesome;
        text-rendering: auto;
        position: absolute;
        right: 10px;
        top: 11px; }
  .filter-bar li.swatch-filter {
    font-size: 1.38em;
    position: relative;
    margin-top: 4px; }
    .filter-bar li.swatch-filter button {
      width: 100%;
      height: 100%;
      display: block;
      border: none; }
      .filter-bar li.swatch-filter button::before {
        content: "";
        background-color: white;
        width: 0.8em;
        height: 0.8em;
        border-radius: 0.4em;
        position: absolute;
        left: 0.5em;
        top: 0.5em; }
      .filter-bar li.swatch-filter button::after {
        content: "\f057";
        font: normal normal normal 14px/1 FontAwesome;
        display: inline;
        text-rendering: auto;
        position: absolute;
        left: 0.18em;
        top: 0.12em;
        font-size: 1.5em; }

@media (max-width: 543.98px) {
  .tab-content {
    padding-left: 0;
    padding-right: 0; } }

@media (max-width: 543.98px) {
  .product-grid {
    padding-top: 0.938em; }
    .product-grid .col-6 {
      padding-left: 5px;
      padding-right: 5px; } }

.search-tips {
  text-align: left;
  margin-top: 1.875rem; }

.category-item {
  margin-bottom: 0.938rem; }

.show-more,
.show-more-content {
  padding: 0.938em;
  clear: both; }

.swatch-mark.color-value[data-selected=true]::after {
  color: #000;
  content: '\f058';
  display: table-caption;
  font-family: 'FontAwesome';
  font-size: 1.625em;
  left: 0.295em;
  position: absolute; }

.swatch-mark.color-value.selected::after {
  background: #fff;
  border-radius: 50%;
  color: #000;
  content: '\f058';
  display: table-caption;
  font-family: 'FontAwesome';
  font-size: 1.625em;
  height: 0.75em;
  left: 0.31em;
  line-height: 0.8em;
  position: absolute;
  top: 0.35em;
  width: 0.8em; }

.search-nav {
  margin-bottom: 1em;
  border-bottom: 0.063em solid #ccc; }
  .search-nav .nav-tabs-wrapper {
    padding: 0; }
    @media (max-width: 543.98px) {
      .search-nav .nav-tabs-wrapper {
        width: 100%; } }
    .search-nav .nav-tabs-wrapper .nav-tabs {
      border-bottom: 0 none; }
  .search-nav .nav-link:focus {
    background-color: #f9f9f9; }

.product-options .quantity {
  position: absolute;
  bottom: 0;
  right: 0; }

/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
.swatch-circle-beige {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5rem;
  height: 2.5rem;
  background: #f5f5dc;
  border-radius: 1.25rem;
  display: block;
  position: relative; }
  .swatch-circle-beige.disabled {
    opacity: 0.2; }

.swatch-square-beige {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5rem;
  height: 2.5rem;
  background: #f5f5dc;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-beige.disabled {
    opacity: 0.2; }

.swatch-filter-beige {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #f5f5dc;
  border-radius: 0;
  background-color: #f5f5dc;
  display: block;
  position: relative; }
  .swatch-filter-beige.disabled {
    opacity: 0.2; }

.swatch-circle-black {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5rem;
  height: 2.5rem;
  background: #000;
  border-radius: 1.25rem;
  display: block;
  position: relative; }
  .swatch-circle-black.disabled {
    opacity: 0.2; }

.swatch-square-black {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5rem;
  height: 2.5rem;
  background: #000;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-black.disabled {
    opacity: 0.2; }

.swatch-filter-black {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #000;
  border-radius: 0;
  background-color: #000;
  display: block;
  position: relative; }
  .swatch-filter-black.disabled {
    opacity: 0.2; }

.swatch-circle-blue {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5rem;
  height: 2.5rem;
  background: #0056a2;
  border-radius: 1.25rem;
  display: block;
  position: relative; }
  .swatch-circle-blue.disabled {
    opacity: 0.2; }

.swatch-square-blue {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5rem;
  height: 2.5rem;
  background: #0056a2;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-blue.disabled {
    opacity: 0.2; }

.swatch-filter-blue {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #0056a2;
  border-radius: 0;
  background-color: #0056a2;
  display: block;
  position: relative; }
  .swatch-filter-blue.disabled {
    opacity: 0.2; }

.swatch-circle-brown {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5rem;
  height: 2.5rem;
  background: #a52a2a;
  border-radius: 1.25rem;
  display: block;
  position: relative; }
  .swatch-circle-brown.disabled {
    opacity: 0.2; }

.swatch-square-brown {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5rem;
  height: 2.5rem;
  background: #a52a2a;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-brown.disabled {
    opacity: 0.2; }

.swatch-filter-brown {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #a52a2a;
  border-radius: 0;
  background-color: #a52a2a;
  display: block;
  position: relative; }
  .swatch-filter-brown.disabled {
    opacity: 0.2; }

.swatch-circle-green {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5rem;
  height: 2.5rem;
  background: #088310;
  border-radius: 1.25rem;
  display: block;
  position: relative; }
  .swatch-circle-green.disabled {
    opacity: 0.2; }

.swatch-square-green {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5rem;
  height: 2.5rem;
  background: #088310;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-green.disabled {
    opacity: 0.2; }

.swatch-filter-green {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #088310;
  border-radius: 0;
  background-color: #088310;
  display: block;
  position: relative; }
  .swatch-filter-green.disabled {
    opacity: 0.2; }

.swatch-circle-grey {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5rem;
  height: 2.5rem;
  background: #8f979d;
  border-radius: 1.25rem;
  display: block;
  position: relative; }
  .swatch-circle-grey.disabled {
    opacity: 0.2; }

.swatch-square-grey {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5rem;
  height: 2.5rem;
  background: #8f979d;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-grey.disabled {
    opacity: 0.2; }

.swatch-filter-grey {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #8f979d;
  border-radius: 0;
  background-color: #8f979d;
  display: block;
  position: relative; }
  .swatch-filter-grey.disabled {
    opacity: 0.2; }

.swatch-circle-navy {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5rem;
  height: 2.5rem;
  background: #000080;
  border-radius: 1.25rem;
  display: block;
  position: relative; }
  .swatch-circle-navy.disabled {
    opacity: 0.2; }

.swatch-square-navy {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5rem;
  height: 2.5rem;
  background: #000080;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-navy.disabled {
    opacity: 0.2; }

.swatch-filter-navy {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #000080;
  border-radius: 0;
  background-color: #000080;
  display: block;
  position: relative; }
  .swatch-filter-navy.disabled {
    opacity: 0.2; }

.swatch-circle-orange {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5rem;
  height: 2.5rem;
  background: #ffa500;
  border-radius: 1.25rem;
  display: block;
  position: relative; }
  .swatch-circle-orange.disabled {
    opacity: 0.2; }

.swatch-square-orange {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5rem;
  height: 2.5rem;
  background: #ffa500;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-orange.disabled {
    opacity: 0.2; }

.swatch-filter-orange {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #ffa500;
  border-radius: 0;
  background-color: #ffa500;
  display: block;
  position: relative; }
  .swatch-filter-orange.disabled {
    opacity: 0.2; }

.swatch-circle-pink {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5rem;
  height: 2.5rem;
  background: #fe249a;
  border-radius: 1.25rem;
  display: block;
  position: relative; }
  .swatch-circle-pink.disabled {
    opacity: 0.2; }

.swatch-square-pink {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5rem;
  height: 2.5rem;
  background: #fe249a;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-pink.disabled {
    opacity: 0.2; }

.swatch-filter-pink {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #fe249a;
  border-radius: 0;
  background-color: #fe249a;
  display: block;
  position: relative; }
  .swatch-filter-pink.disabled {
    opacity: 0.2; }

.swatch-circle-purple {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5rem;
  height: 2.5rem;
  background: #800080;
  border-radius: 1.25rem;
  display: block;
  position: relative; }
  .swatch-circle-purple.disabled {
    opacity: 0.2; }

.swatch-square-purple {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5rem;
  height: 2.5rem;
  background: #800080;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-purple.disabled {
    opacity: 0.2; }

.swatch-filter-purple {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #800080;
  border-radius: 0;
  background-color: #800080;
  display: block;
  position: relative; }
  .swatch-filter-purple.disabled {
    opacity: 0.2; }

.swatch-circle-red {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5rem;
  height: 2.5rem;
  background: #f00;
  border-radius: 1.25rem;
  display: block;
  position: relative; }
  .swatch-circle-red.disabled {
    opacity: 0.2; }

.swatch-square-red {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5rem;
  height: 2.5rem;
  background: #f00;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-red.disabled {
    opacity: 0.2; }

.swatch-filter-red {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #f00;
  border-radius: 0;
  background-color: #f00;
  display: block;
  position: relative; }
  .swatch-filter-red.disabled {
    opacity: 0.2; }

.swatch-circle-white {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5rem;
  height: 2.5rem;
  background: #fff;
  border-radius: 1.25rem;
  display: block;
  position: relative; }
  .swatch-circle-white.disabled {
    opacity: 0.2; }

.swatch-square-white {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5rem;
  height: 2.5rem;
  background: #fff;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-white.disabled {
    opacity: 0.2; }

.swatch-filter-white {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #fff;
  border-radius: 0;
  background-color: #fff;
  display: block;
  position: relative; }
  .swatch-filter-white.disabled {
    opacity: 0.2; }

.swatch-circle-yellow {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5rem;
  height: 2.5rem;
  background: #ff0;
  border-radius: 1.25rem;
  display: block;
  position: relative; }
  .swatch-circle-yellow.disabled {
    opacity: 0.2; }

.swatch-square-yellow {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5rem;
  height: 2.5rem;
  background: #ff0;
  border-radius: 0;
  display: block;
  position: relative; }
  .swatch-square-yellow.disabled {
    opacity: 0.2; }

.swatch-filter-yellow {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #ff0;
  border-radius: 0;
  background-color: #ff0;
  display: block;
  position: relative; }
  .swatch-filter-yellow.disabled {
    opacity: 0.2; }

.swatch-circle-miscellaneous,
.swatch-square-miscellaneous {
  background: -webkit-gradient(linear, left top, right top, from(red), color-stop(orange), color-stop(yellow), color-stop(green), color-stop(blue), color-stop(indigo), to(violet));
  background: linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet);
  border: 0;
  border-radius: 1.25em;
  display: block;
  height: 2.5em;
  position: relative;
  -webkit-transform: rotate(35deg);
          transform: rotate(35deg);
  width: 2.5em; }
  .swatch-circle-miscellaneous.disabled,
  .swatch-square-miscellaneous.disabled {
    opacity: 0.2; }
  .swatch-circle-miscellaneous.selected::after,
  .swatch-square-miscellaneous.selected::after {
    -webkit-transform: rotate(-35deg);
            transform: rotate(-35deg); }

.swatch-square-miscellaneous {
  border-radius: 0;
  -webkit-transform: unset;
          transform: unset; }
  .swatch-square-miscellaneous.selected::after {
    -webkit-transform: unset;
            transform: unset; }

.category-tile {
  position: relative; }
  .category-tile h1,
  .category-tile h2 {
    font-size: 1.75rem;
    position: absolute;
    bottom: 1.875rem;
    left: 1.875rem;
    color: #fff; }
  .category-tile::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(60%, transparent), to(rgba(0, 0, 0, 0.5)));
    background-image: linear-gradient(to bottom, transparent 60%, rgba(0, 0, 0, 0.5) 100%); }

/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
.grid-footer {
  padding: 0; }
  @media (min-width: 544px) {
    .grid-footer {
      padding-left: 15px;
      padding-right: 15px; } }
  .grid-footer .infinitescroll-loader {
    position: relative;
    display: block;
    width: 100%;
    height: 7.8125rem; }

.pagination-wrapper {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: 1.25rem 0 0; }
  @media (min-width: 769px) {
    .pagination-wrapper {
      margin: 3.125rem 0 0; } }

.pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  height: auto;
  padding: 0;
  background-color: transparent; }
  @media (min-width: 769px) {
    .pagination {
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between; } }

.page-numbers {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transition: all 400ms ease;
  transition: all 400ms ease;
  color: var(--color-primary);
  font-family: SofiaPro, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1.25rem;
  line-height: 1;
  border-radius: 0.1875rem;
  padding: 0.625rem 0.75rem; }
  .page-numbers:hover:not(.disabled) {
    background-color: var(--color-grey3);
    text-decoration: none; }
  .page-numbers.current {
    font-family: SofiaProBold, Arial, sans-serif;
    font-weight: 600;
    background-color: var(--color-blue);
    color: var(--color-white);
    pointer-events: none; }
  .page-numbers.page-suggest {
    pointer-events: none; }
  .page-numbers.prev, .page-numbers.next, .page-numbers.first, .page-numbers.last {
    font-size: 1.5rem; }
    .page-numbers.prev.disabled, .page-numbers.next.disabled, .page-numbers.first.disabled, .page-numbers.last.disabled {
      pointer-events: none;
      background-color: transparent;
      opacity: 0.3; }

/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
.compare-bar-wrapper {
  bottom: 0;
  display: none;
  position: fixed;
  width: 100%;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.65);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease; }
  .compare-bar-wrapper .toggle-compare {
    position: absolute;
    right: 1.125rem;
    top: -2.1875rem;
    background-color: var(--color-grey1);
    border: 1px solid var(--color-hr-border-color);
    border-bottom: 0; }
    .compare-bar-wrapper .toggle-compare .hide-text {
      display: inline; }
    .compare-bar-wrapper .toggle-compare .show-text {
      display: none; }
  .compare-bar-wrapper.hidden {
    bottom: -8.25rem; }
    .compare-bar-wrapper.hidden .toggle-compare .hide-text {
      display: none; }
    .compare-bar-wrapper.hidden .toggle-compare .show-text {
      display: inline; }
  .compare-bar-wrapper .compare-bar {
    padding-top: 1rem;
    padding-bottom: 1rem; }
  .compare-bar-wrapper .product-slots {
    height: 6.25em; }
  .compare-bar-wrapper .slot {
    background-color: var(--color-white);
    height: 5em;
    margin: 0 auto;
    position: relative;
    width: 6.788em; }
    .compare-bar-wrapper .slot:not([data-pid]) {
      border: 1px dashed #ccc; }
    .compare-bar-wrapper .slot img {
      float: left;
      height: 100%;
      position: static; }
    .compare-bar-wrapper .slot i {
      color: #7e7e7e;
      float: left;
      font-size: 1rem;
      position: relative;
      top: 50%;
      -webkit-transform: translate(65%, -50%);
              transform: translate(65%, -50%); }
    .compare-bar-wrapper .slot .close {
      background-color: #f0eeee;
      height: 100%;
      width: 25%;
      opacity: 1; }
  .compare-bar-wrapper .action {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
    .compare-bar-wrapper .action .btn {
      width: 100%; }
  .compare-bar-wrapper .clear-all {
    margin-top: 0.625rem; }
  .compare-bar-wrapper .min-products-msg {
    color: #fff;
    font-size: 0.75em;
    line-height: 1.375rem;
    padding: 0.438rem; }

.product-comparison .back-to-results {
  margin: 1em 0; }

.product-comparison .product-header > .col {
  padding: 0; }

.product-tile .tile-body .compare {
  font-size: 0.875em; }

.table-striped-column td:nth-of-type(odd) {
  background-color: #f0eeee; }

.form-check-label .store-name {
  font-size: 1rem;
  line-height: 1.5rem;
  color: var(--color-blue); }
  @media (min-width: 769px) {
    .form-check-label .store-name {
      font-size: 1.25rem;
      line-height: 1.75rem; } }

.form-check-label .store-full-address {
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: var(--color-grey6);
  margin-bottom: 0.5rem; }
  @media (min-width: 769px) {
    .form-check-label .store-full-address {
      font-size: 1rem;
      line-height: 1.5rem; } }

.form-check-label .store-get-direction {
  background-color: var(--color-grey1);
  padding: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between; }
  .form-check-label .store-get-direction .store-map {
    font-size: 0.875rem;
    line-height: 1rem;
    text-decoration: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }

.form-check-label .storelocator-phone {
  text-decoration: none; }

.form-check-label .store-phone-no {
  margin-top: 1rem 0;
  padding: 1rem; }

.myPrefferedWorkshop .makeItMyWorkshopBtn {
  display: none; }

.myPrefferedWorkshop .inStock {
  color: var(--color-green); }

.myPrefferedWorkshop .outOfStock,
.myPrefferedWorkshop .unavailableStock {
  color: var(--color-red); }

.results-card .form-check {
  padding-left: 0; }

.results-card .form-check-label {
  width: 100%;
  background-color: var(--color-white); }
  .results-card .form-check-label .store-details {
    margin-left: 0; }

.results-card .card-body {
  padding: 1rem; }
  @media (min-width: 769px) {
    .results-card .card-body {
      max-width: 50.625rem; } }

.results-card > div:nth-of-type(odd) {
  background-color: var(--color-white); }

@media (min-width: 769px) {
  .results-card.results {
    overflow-y: initial;
    overflow-x: initial;
    max-height: none; }
    .results-card.results .card {
      border: 0; } }

.results-card .store-availability-accordion .card {
  border: 0; }

.results-card .collapse .card-body {
  border: 0;
  display: none; }

.results-card .collapse.show .card-body {
  display: block; }

.results-card .inStock {
  color: var(--color-green); }

.results-card .outOfStock,
.results-card .unavailableStock {
  color: var(--color-red); }

.modal-dialog-storelocator.plp .prefered-store, .modal-dialog-storelocator.checkout .prefered-store, .modal-dialog-storelocator.cart .prefered-store {
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2;
  margin-bottom: 0; }

.modal-dialog-storelocator.plp .results, .modal-dialog-storelocator.checkout .results, .modal-dialog-storelocator.cart .results {
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1; }

.breadcrumbs {
  margin-bottom: 1rem; }
  .breadcrumbs .search-phrases {
    font-size: 0.75rem; }
    @media (min-width: 769px) {
      .breadcrumbs .search-phrases {
        font-size: 0.875rem; } }
    .breadcrumbs .search-phrases .search-keywords {
      font-family: SofiaProBold, Arial, sans-serif;
      font-weight: 600;
      color: var(--skin-breadcrumb-color); }

.search-nav {
  margin-bottom: 0;
  border-bottom: 0; }

@media (max-width: 543.98px) {
  .tab-content {
    padding-left: 15px;
    padding-right: 15px; } }

.secondary-bar.hide {
  display: none; }

.secondary-bar.show {
  display: block; }

.grid-header,
.content-grid-header {
  margin-top: 0; }
  @media (max-width: 768.98px) {
    .grid-header .result-count,
    .content-grid-header .result-count {
      padding-bottom: 0.938em; } }

.grid-header .result-count p {
  margin-bottom: 0;
  line-height: 1.5rem; }

.grid-header .result-count .search-result-count {
  font-size: 0.875rem; }

.grid-header .filter-results {
  padding: 0;
  text-align: left;
  font-size: 0.875rem;
  line-height: 1rem;
  font-family: SofiaProBold, Arial, sans-serif;
  font-weight: 600;
  color: var(--color-blue);
  -webkit-box-pack: unset;
      -ms-flex-pack: unset;
          justify-content: unset; }
  .grid-header .filter-results svg {
    width: 1.25rem;
    position: relative;
    top: -0.1875rem; }

.filter-header .header-bar {
  background: none;
  padding: 2.5rem 0 2rem;
  border: 0; }
  .filter-header .header-bar span {
    font-size: 1rem;
    line-height: 1.5rem;
    color: var(--color-blue-dark);
    font-family: SofiaProBold, Arial, sans-serif;
    font-weight: 600; }
  .filter-header .header-bar .btn-outline-primary {
    padding: 0 1.25rem 0 0;
    font-size: 1rem;
    line-height: 1.5rem;
    color: var(--color-blue);
    font-family: SofiaProBold, Arial, sans-serif;
    font-weight: 600;
    border: 0; }

.refinement-bar {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  overflow-y: auto; }
  @media (max-width: 768.98px) {
    .refinement-bar {
      z-index: 9999; } }
  .refinement-bar.hide {
    width: 0;
    max-width: 0;
    overflow: hidden;
    opacity: 0;
    padding: 0; }

@media (min-width: 769px) {
  .refinements {
    margin-top: 0.625rem;
    padding-right: 1rem; } }

.refinements ul li {
  padding: 0; }
  .refinements ul li.color-attribute {
    display: list-item;
    margin: 0 0.5rem 0.5rem 0; }
    .refinements ul li.color-attribute button {
      display: block; }
  .refinements ul li.size-attribute {
    margin: 0 0.5rem 0.5rem 0; }
    .refinements ul li.size-attribute button {
      height: 2.5rem;
      width: 2.5rem;
      padding: 0.25rem;
      font-size: 0.75rem; }
  .refinements ul li .custom-checkbox .custom-control-label,
  .refinements ul li .custom-radio .custom-control-label {
    vertical-align: unset; }
  .refinements ul li:not(:last-child) .custom-checkbox,
  .refinements ul li:not(:last-child) .custom-radio {
    margin-bottom: 0.9375rem; }

.search-results .store-switch {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding-bottom: 1.125rem; }
  @media (max-width: 991.98px) {
    .search-results .store-switch h6 {
      font-size: 1rem;
      line-height: 1.5rem; } }
  .search-results .store-switch .store-toggle {
    padding: 0;
    width: 2.875rem; }
    .search-results .store-switch .store-toggle .custom-control-label:before {
      left: 0; }
    .search-results .store-switch .store-toggle .custom-control-label:after {
      left: 0;
      -webkit-transform: translateX(0.3125rem);
              transform: translateX(0.3125rem); }
    .search-results .store-switch .store-toggle .custom-control-label .switch-text-label {
      left: 1.5rem; }
    .search-results .store-switch .store-toggle .custom-control-input:checked ~ .custom-control-label:after {
      left: 0;
      -webkit-transform: translateX(1.4375rem);
              transform: translateX(1.4375rem); }
    .search-results .store-switch .store-toggle .custom-control-input:checked ~ .custom-control-label .switch-text-label {
      left: 0.1875rem; }

.search-results .select-store-link {
  padding-bottom: 1.5625rem;
  border-bottom: 0.0625rem solid var(--color-blue200);
  margin-bottom: 2.125rem;
  font-family: SofiaProBold, Arial, sans-serif;
  font-weight: 600; }
  @media (min-width: 769px) {
    .search-results .select-store-link {
      padding-bottom: 1.5625rem;
      border: 0;
      margin-bottom: 0; } }
  .search-results .select-store-link a {
    font-size: 0.875rem;
    line-height: 1rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    text-transform: capitalize;
    cursor: default; }
    .search-results .select-store-link a svg {
      width: 1.0625rem;
      height: 1.0625rem;
      margin-right: 0.3125rem; }
    .search-results .select-store-link a:hover span {
      text-decoration: underline; }

.refinement.card {
  margin-bottom: 1.5rem; }
  .refinement.card .filter-by h6 {
    font-size: 0.875rem;
    line-height: 1.25rem; }
  .refinement.card .refinement-showall-clear {
    width: 100%; }
    .refinement.card .refinement-showall-clear a {
      text-transform: capitalize; }
    .refinement.card .refinement-showall-clear .clear-refinement-selection a {
      color: var(--color-red); }
  .refinement.card.refinement-color ul li:not(:last-child) .custom-checkbox {
    margin-bottom: 1.375rem; }
  .refinement.card.refinement-color .refinement-label {
    padding-left: 0.5rem;
    position: relative;
    top: 0.1875rem; }
    .refinement.card.refinement-color .refinement-label.selected {
      font-family: SofiaProBold, Arial, sans-serif;
      font-weight: 600; }
  .refinement.card.refinement-color .swatch-white .custom-control-label::before {
    border-color: var(--color-grey5);
    border-width: 0.0625rem; }
  .refinement.card.refinement-color .swatch-multi .custom-control-label::before {
    background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3Qgd2lkdGg9IjMyIiBoZWlnaHQ9IjMyIiByeD0iMiIgZmlsbD0idXJsKCNwYWludDBfbGluZWFyXzIwODNfODI3MDgpIi8+CjxkZWZzPgo8bGluZWFyR3JhZGllbnQgaWQ9InBhaW50MF9saW5lYXJfMjA4M184MjcwOCIgeDE9IjE2IiB5MT0iMCIgeDI9IjE2IiB5Mj0iMzIiIGdyYWRpZW50VW5pdHM9InVzZXJTcGFjZU9uVXNlIj4KPHN0b3Agc3RvcC1jb2xvcj0iIzA5Mjk3MCIvPgo8c3RvcCBvZmZzZXQ9IjAuMTQ1ODMzIiBzdG9wLWNvbG9yPSIjNUExRjY4Ii8+CjxzdG9wIG9mZnNldD0iMC4yNzA4MzMiIHN0b3AtY29sb3I9IiM0MzNCREMiLz4KPHN0b3Agb2Zmc2V0PSIwLjM4MDIwOCIgc3RvcC1jb2xvcj0iIzVGRDRGMSIvPgo8c3RvcCBvZmZzZXQ9IjAuNTM2NDU4IiBzdG9wLWNvbG9yPSIjODNFMjlEIi8+CjxzdG9wIG9mZnNldD0iMC42NDA2MjUiIHN0b3AtY29sb3I9IiNDM0U0NUIiLz4KPHN0b3Agb2Zmc2V0PSIwLjc4NjQ1OCIgc3RvcC1jb2xvcj0iI0YyQjEzRSIvPgo8c3RvcCBvZmZzZXQ9IjAuODkwNjI1IiBzdG9wLWNvbG9yPSIjRUE1OTI5Ii8+CjxzdG9wIG9mZnNldD0iMSIgc3RvcC1jb2xvcj0iI0VBMzgyNSIvPgo8L2xpbmVhckdyYWRpZW50Pgo8L2RlZnM+Cjwvc3ZnPgo=); }
  .refinement.card.refinement-color .custom-control-label {
    background: transparent;
    width: 2rem;
    height: 2rem;
    border-radius: 0.25rem;
    display: inline-block;
    position: absolute;
    left: 0;
    border: 0.125rem solid transparent; }
    .refinement.card.refinement-color .custom-control-label::before {
      background: var(--color-white);
      width: 2rem;
      height: 2rem;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      border-width: 0; }
    .refinement.card.refinement-color .custom-control-label::after {
      display: none; }
    .refinement.card.refinement-color .custom-control-label.selected {
      border-color: var(--color-blue);
      background: var(--color-white); }
      .refinement.card.refinement-color .custom-control-label.selected::before {
        width: 1.5rem;
        height: 1.5rem; }
  .refinement.card .card-header {
    padding: 0;
    border-bottom: 1px solid var(--color-blue200); }
    .refinement.card .card-header .title {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      padding: 0 0 1rem;
      line-height: 1; }
      .refinement.card .card-header .title::after {
        display: none; }
  .refinement.card .card-body {
    border-bottom: 1px solid transparent; }
    .refinement.card .card-body.refinement-card[id*="color"] ul.values {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap; }
      .refinement.card .card-body.refinement-card[id*="color"] ul.values li {
        width: 50%; }
    .refinement.card .card-body .refinement-search {
      position: relative; }
      .refinement.card .card-body .refinement-search .refinement-filter {
        padding: 0.625rem 3.25rem 0.625rem 0.75rem;
        border-radius: 0;
        margin-bottom: 1.5625rem;
        border-radius: 0.25rem;
        font-family: SofiaPro, Arial, sans-serif;
        font-style: normal;
        font-weight: normal;
        color: var(--color-grey4); }
      .refinement.card .card-body .refinement-search .cancle-icon {
        position: absolute;
        right: 0.5rem;
        top: 50%;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
        cursor: pointer; }
        .refinement.card .card-body .refinement-search .cancle-icon svg {
          color: var(--color-grey5); }
    .refinement.card .card-body .refinement-label {
      font-size: 0.875rem;
      line-height: 1.25rem;
      font-family: SofiaPro, Arial, sans-serif;
      font-style: normal;
      font-weight: normal; }
  .refinement.card.active .card-header {
    border-color: transparent; }
  .refinement.card.active .card-body {
    border-color: var(--color-blue200); }
    .refinement.card.active .card-body ul.values {
      padding-bottom: 1.375rem; }
  .refinement.card.active .refinement-headline .arrow {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    position: relative;
    top: 0.3125rem; }
  .refinement.card .refinement-headline {
    font-size: 1rem;
    line-height: 1.5rem;
    font-family: SofiaPro, Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    margin: 0;
    text-transform: capitalize;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    @media (min-width: 769px) {
      .refinement.card .refinement-headline.store-header-text {
        font-size: 0.875rem; } }
    .refinement.card .refinement-headline .arrow {
      display: inline-block;
      margin-left: auto; }
  .refinement.card .card-body {
    padding: 0.75rem 0 0; }
  .refinement.card .values {
    margin-bottom: 0;
    padding: 0; }
  .refinement.card.refinement-category li {
    margin-left: 0; }
    .refinement.card.refinement-category li.has-subs > button {
      text-transform: uppercase; }
    .refinement.card.refinement-category li.has-subs:before {
      font-family: 'FontAwesome';
      font-size: 0.875rem;
      content: "\F078";
      position: relative;
      top: -1px;
      line-height: 1; }
    .refinement.card.refinement-category li.selected > button {
      font-weight: 600;
      pointer-events: none; }
  .refinement.card.refinement-category ul ul {
    margin-left: 1rem; }
  .refinement.card.mobile-sort .radio-sort {
    padding-bottom: 0.625rem; }
  .refinement.card.mobile-sort .custom-radio {
    padding-bottom: 0.9375rem; }

@media (min-width: 769px) {
  .filter-bar {
    margin-top: 1.125rem; } }

.filter-bar ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0;
  margin-bottom: 0; }

.filter-bar li {
  margin: 0 0 1rem; }
  .filter-bar li button.reset {
    border: 0;
    padding: 0; }
  .filter-bar li button:hover {
    background: none; }
  .filter-bar li.swatch-filter {
    margin-top: 0;
    border: 1px solid #ccc;
    padding: 0.1875rem 0.3125rem;
    border-radius: 0.1875rem;
    position: relative;
    height: 100%; }
    .filter-bar li.swatch-filter button {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      color: var(--color-black);
      border: none;
      padding: 0 1.5625rem 0 0; }
      .filter-bar li.swatch-filter button:before {
        display: none; }
      .filter-bar li.swatch-filter button:after {
        display: none; }
  .filter-bar li.filter-value {
    padding: 0.625rem 0.3125rem 0.625rem 0.75rem;
    border-radius: 0.25rem;
    position: relative;
    height: 100%;
    margin: 0 1rem 1rem 0;
    border: 0;
    background: var(--color-grey1); }
    .filter-bar li.filter-value button {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      color: var(--color-blue-dark);
      border: none;
      padding: 0;
      font-size: 0.75rem;
      line-height: 0.75rem;
      font-family: SofiaProBold, Arial, sans-serif;
      font-weight: 600; }
      .filter-bar li.filter-value button:after {
        display: none; }
      .filter-bar li.filter-value button svg {
        width: 1.5rem;
        color: var(--color-blue-dark);
        margin-left: 0.1875rem; }

@media (min-width: 992px) {
  .grid-wrapper {
    padding: 0; } }

.product-grid {
  margin-bottom: 3.125rem; }
  @media (min-width: 769px) {
    .product-grid {
      margin-bottom: 4.375rem; } }
  @media (max-width: 543.98px) {
    .product-grid {
      padding-top: 0; }
      .product-grid .col-6 {
        padding-left: 15px;
        padding-right: 15px; } }

.grid-tile-wrapper {
  margin-bottom: 2.5rem; }
  @media (min-width: 769px) {
    .grid-tile-wrapper {
      padding: 0.625rem 0.9375rem; } }
  @media (min-width: 992px) {
    .grid-tile-wrapper {
      padding: 0.625rem 1.5rem;
      margin-bottom: 3.4375rem; } }

.section-header {
  display: block;
  background: var(--color-blue100);
  font-family: SofiaProBold, Arial, sans-serif;
  font-weight: 600;
  margin: 0;
  padding: 1rem; }
  .section-header p {
    margin: 0.875rem 0;
    font-family: SofiaProBold, Arial, sans-serif;
    font-weight: 600; }
    .section-header p .no-hits-search-term {
      color: #d00a2d;
      font-family: SofiaProBoldItalic, Arial, sans-serif;
      font-weight: 600;
      font-style: italic; }

.responsive-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin: 0 auto; }
  .responsive-grid .section-header p {
    margin: 0.875rem 0; }

.no-result {
  display: block;
  margin: 1.25rem 0 0; }
  .no-result .nohit-search-label {
    font-family: SofiaProBold, Arial, sans-serif;
    font-weight: 600;
    margin-bottom: 0.3125rem; }
  .no-result .site-search-no-result form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .no-result .site-search-no-result form .search-field {
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      padding: 0 0.625rem;
      border: 0.0625rem solid var(--color-grey5);
      border-radius: 0.3125rem;
      width: 100%;
      height: 2.5rem;
      font-family: SofiaPro, Arial, sans-serif;
      font-style: normal;
      font-weight: normal;
      text-indent: 0;
      color: var(--color-blue-dark);
      background-color: var(--color-white); }
      .no-result .site-search-no-result form .search-field:focus {
        border: 0.0625rem solid var(--color-blue-dark);
        -webkit-box-shadow: none;
                box-shadow: none; }
      .no-result .site-search-no-result form .search-field::-webkit-input-placeholder {
        color: transparent; }
      .no-result .site-search-no-result form .search-field::-moz-placeholder {
        color: transparent; }
      .no-result .site-search-no-result form .search-field:-ms-input-placeholder {
        color: transparent; }
      .no-result .site-search-no-result form .search-field::-ms-input-placeholder {
        color: transparent; }
      .no-result .site-search-no-result form .search-field::placeholder {
        color: transparent; }
    .no-result .site-search-no-result form button {
      cursor: pointer;
      display: inline-block;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      padding: 0.5rem;
      font-family: SofiaPro, Arial, sans-serif;
      font-style: normal;
      font-weight: normal;
      text-align: center;
      text-decoration: none;
      color: var(--color-white);
      background-color: var(--color-red);
      border: 0.0625rem solid var(--color-red);
      margin-top: 0.625rem;
      border-radius: 0.3125rem; }

.noresult-cat ul {
  padding-left: 0; }
  .noresult-cat ul li a {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; }

.sort-result-cout .result-count span {
  font-size: 1rem;
  line-height: 1.5rem;
  color: var(--color-blue-dark);
  text-transform: lowercase;
  font-family: SofiaPro, Arial, sans-serif;
  font-style: normal;
  font-weight: normal; }

.sort-result-cout .sortby .sortby-text {
  font-size: 0.875rem;
  line-height: 1rem;
  color: var(--color-blue);
  font-family: SofiaProBold, Arial, sans-serif;
  font-weight: 600;
  width: 3.125rem;
  display: inline-block; }

.sort-result-cout .sortby span.selected-value {
  position: absolute;
  opacity: 0;
  z-index: -1;
  display: inline-block; }

.sort-result-cout .sortby .custom-select {
  font-size: 0.875rem;
  line-height: 1rem;
  color: var(--color-blue);
  font-family: SofiaProBold, Arial, sans-serif;
  font-weight: 600;
  border: 0;
  display: inline-block;
  height: auto;
  position: relative;
  top: 0.0625rem;
  background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEyIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xIDEuNUw2IDYuNUwxMSAxLjUiIHN0cm9rZT0iIzAwNTZBMiIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==);
  background-position: center right 0;
  background-repeat: no-repeat;
  padding-left: 0.1875rem;
  padding-right: 0.9375rem;
  width: 3.125rem; }
  .sort-result-cout .sortby .custom-select option {
    font-size: 0.875rem;
    line-height: 1.25rem;
    padding: 0.5rem 0.9375rem; }

.add-to-cart-error {
  color: var(--color-danger);
  font-size: 0.75rem; }

body.heartbox .search-results .store-switch input[type=checkbox] + label:before {
  border-color: var(--skin-hb-switch-border-color); }

body.heartbox .search-results .select-store-link a {
  color: var(--color-teal); }
  body.heartbox .search-results .select-store-link a svg {
    color: var(--color-teal); }

body.heartbox .refinement.card .refinement-headline .arrow svg {
  color: var(--color-teal); }

body.heartbox .refinement.card .refinement-showall-clear a {
  color: var(--color-teal); }

body.heartbox .grid-header .filter-results {
  color: var(--color-teal); }
  body.heartbox .grid-header .filter-results svg {
    color: var(--color-teal); }

body.heartbox .header-bar button.close svg {
  color: var(--color-teal); }

body.bearcave .search-results .store-switch input[type=checkbox] + label:before {
  border-color: var(--color-red); }

body.bearcave .search-results .select-store-link a svg {
  color: var(--color-red); }

body.bearcave .refinement.card .refinement-headline .arrow svg {
  color: var(--color-red); }

body.bearcave .refinement.card .refinement-showall-clear a {
  color: var(--color-red); }

body.bearcave .grid-header .filter-results {
  color: var(--color-midnight); }
  body.bearcave .grid-header .filter-results svg {
    color: var(--color-red); }

body.bearcave .header-bar button.close svg {
  color: var(--color-red); }
